import { render, staticRenderFns } from "./zy_cul.vue?vue&type=template&id=06329cfc&scoped=true&"
import script from "./zy_cul.vue?vue&type=script&lang=js&"
export * from "./zy_cul.vue?vue&type=script&lang=js&"
import style0 from "./zy_cul.vue?vue&type=style&index=0&id=06329cfc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06329cfc",
  null
  
)

export default component.exports