<template>
  <div class="out" :class="$i18n.locale == 'en' ? 'en_style' : ''">
    <div class="header">
      <div class="logo Title">
        <div class="Chinese">{{ $t('main.holdertran') }}</div>
      </div>
      <div class="right-icon">
        <img src="../assets/img/logo-icon.png" alt />
        <div class="cc-wc">{{ $t('main.titleCenter') }}</div>
      </div>
    </div>
    <div class="body">
      <div class="zb-top">
        <img :src="goods.pictures.length ? goods.pictures[0] + '?width=350' : ''" alt />
        <div class="info">
          <div class>
            <span>文创品名称：</span>
            <span>{{ goods.name }}</span>
          </div>
          <div>
            <span>长城文创品编码：</span>
            <span>{{ goods.serial_number }}</span>
          </div>
          <div>
            <span>珍宝等级:</span>
            <span>文创品</span>
          </div>
        </div>
      </div>
      <!-- <div class="list_item" style="font-size:0.18rem"  v-show="!$route.query.id">
                <span>{{$t('main.trname')}}：</span>
                <span style="color:#3662EC">{{goods.name}}</span>
            </div>
            <div class="list_item">
                {{$t('main.afthc')}}
      </div>-->
      <div class="zb-edit">
        <div class="zb_title">
          <img src="../assets/img/zy_t_icon.png" />
          {{ $t('main.afthc') }}
        </div>
        <div class="zb_text">{{ $t('main.holderinshow') }}</div>
        <div class="zb_main">
          <div class="flex check_box" @click="changeCustom(false)">
            <!-- @change="recommend($event,item.id)" -->
            <input type="radio" name="test" :checked="!is_custom" />
            <label class="list_input">
              <div>{{ user.username }}</div>
            </label>
          </div>
          <div class="flex check_box" @click="changeCustom(true)">
            <input type="radio" name="test" :checked="is_custom" />
            <label class="list_input">
              <input type="text" :placeholder="$t('main.userdefin')" v-model="custom_name" />
            </label>
          </div>
          <div class="zb_tips">{{ $t('main.notehold') }}</div>
        </div>
        <!-- v-show="$route.query.pay === 1" -->
        <div >
                    <div style="font-size:0.15rem;color:#707070;margin:0.2rem 0;">支付区块链服务费：
                        <span style="color:#fe0000">￥{{ goods.level.transfer_fee }}</span>
                    </div>
                    <div class="zb_tips">注：支付完成后，上家收到申请，同意即完成新任持有者认证</div>
        </div>
        
        <div class="upload" @click="upload">支付并认证</div>
      </div>

      <!-- <div class="list_item flex" v-show="!$route.query.id">
                <div>{{$t('main.certrinfo')}}</div>
                <div class="">
                    <div class="list_item">
                        <span>{{$t('main.trname')}}：</span>
                        <span>{{goods.name}}</span>
                    </div>
                     <div class="list_item">
                        <span>{{$t('main.gwtc')}}</span>
                        <span style="color:#fe0000">{{goods.serial_number}}</span>
                    </div>
                     <div class="list_item">
                        <span>{{$t('main.trorvalue')}}</span>
                        <span>￥{{format_with_regex(goods.price)}}</span>
                    </div>
                </div>

            </div>
            <div class="list_item flex">
                
            </div>
            <div class="agree-box1" v-show="is_xy_show">
					<input @input="checkedFlag1" type="checkbox" value="" id="agree-select1" :checked="isKnow" />
					<label id="" for="agree-select1"> {{$t('main.agreeC')}} </label>
					<span class="blue_xy" @click.stop="showContent">《{{xy.title}}》</span>
      </div>-->
    </div>
    <div class="pop pop_mask" v-show="shouquan" @click="shouquan = false"></div>
    <privacy ref="privacy" :more="'&id='+this.$route.query.id + ($route.query.qrCode?`&qrCode=${$route.query.qrCode}`:'')"></privacy>
  </div>
</template>

<script>
import { Dialog } from "vant";
import privacy from "../components/privacy.vue";
export default {
  components: {
    privacy
  },
  data() {
    return {
      goods: {
        name: "",
        serial_number: "",
        price: "",
        id: "",
        pictures: [],
        level: {
          auth_fee: ""
        }
      },
      user: {
        id: 0,
        username: "",
        avatar: ""
      },
      is_custom: false,
      custom_name: "",
      shouquan: false,
      isKnow: true,
      xy: {
        title: "",
        content: ""
      },
      is_xy_show: false,
      isToken: false
    };
  },
  async mounted() {
    let newdata = ''
			await this.axios.post('common/encrypt',{type:3 + (this.$i18n.locale == 'en' ? 6 : 0)}).then((res)=>{
				newdata = res.data.data.data_encrypted
			})
    this.axios
      .post("/agreement/detail", {
        data:newdata
      })
      .then(res => {
        if (!res.data.data.title || !res.data.data.content) {
          this.isKnow = true;
          this.is_xy_show = false;

          return;
        }
        this.is_xy_show = true;
        this.xy.title = res.data.data.title;
        this.xy.content = res.data.data.content;
      });
    
      let querys = "";
      if (this.$route.query.phone) {
        let phone = this.$route.query.phone;
        let nickName = this.$route.query.phone_code;
        querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`;
      }
      this.axios
        .get(
          `/collectionder/apply?goods_derivative_id=${this.$route.query.id}&code=${this.$route.query.code}&qrCode=${this.$route.query.qrCode}` +
            querys
        )
        .then(res => {
          if (res.data.code != 0) {
            if (res.data.msg == "请填写手机号") {
              this.$refs.privacy.openPopup();
              return;
            } else if (res.data.msg == "请授权登录") {
              this.shouquan = true;
            } else {
              weui.alert('该二维码已被使用，请返回重新获取最新二维码', {
                buttons: [{ label: this.$t("main.confIrm") }]
              });
            }
            return;
          }
          this.isToken = true;
          this.goods.name = res.data.data.goods_derivative.name;
          this.goods.id = res.data.data.goods_derivative.id;
          this.goods.serial_number = res.data.data.goods_derivative.serial_number;
        //   this.goods.price = res.data.data.goods.price;
          this.goods.pictures = res.data.data.goods_derivative.pictures || [];
          this.goods.level = res.data.data.goods_derivative.level;
          this.user.id = res.data.data.user.id;
          this.user.username = res.data.data.user.username;
          this.user.avatar = res.data.data.user.avatar;
        });
    
  },
  methods: {
    async upload() {
      if (!this.isKnow) {
        weui.alert(this.$t("main.paoaf"), {
          buttons: [{ label: this.$t("main.confIrm") }]
        });
        return;
      }
      // if (this.$route.query.id) {
      //     let params = {
      //         name: this.user.username,
      //         avatar: this.user.avatar ? this.user.avatar : 'http://t.6a.com/87/011930525c7d7011.png',
      //         is_custom: this.is_custom ? 1 : 0

      //     }
      //     if (this.is_custom) {
      //         params.name = this.custom_name
      //     }
      //     this.axios.post('/collection/edit/' + this.$route.query.id, params).then((res) => {

      //         if (res.data.code == 0) {
      //             weui.alert(this.$t('main.submitsucc'), {
      //                 buttons: [{
      //                     label: this.$t('main.confIrm'), onClick: () => {
      //                         this.$router.push('/zbbox')
      //                     }
      //                 }]
      //             })
      //         }
      //     })
      //     return
      // }
      if (!this.isToken) {
        this.$refs.privacy.showFlag();
        return;
      }
      let params = {
        user_id: this.user.id,
        goods_derivative_id: this.goods.id,
        name: this.user.username,
        avatar: this.user.avatar
          ? this.user.avatar
          : "http://t.6a.com/87/011930525c7d7011.png",
        is_custom: this.is_custom ? 1 : 0,
        qrCode: this.$route.query.qrCode
      };
      if (this.is_custom) {
        if (!this.custom_name) {
          this.msg = this.$t("main.holderinoe");
          this.isError = true;
          return;
        }
        params.name = this.custom_name;
      }
      if (this.$route.query.qrCode) {
        params.qrCode = this.$route.query.qrCode;
      }
      if (this.goods.collector) {
        delete params.verCode;
      }
      this.axios.post("collectionder/apply", params).then(res => {
        if(res.data.code != 0){
          weui.alert(res.data.msg)
          return
        }
        let wxpay = res.data.data
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: wxpay.appId,
            timeStamp: wxpay.timestamp,
            nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
            package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: wxpay.paySign
          },
          res => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              weui.alert(this.$t("main.paysucc"), {
                buttons: [
                  {
                    label: this.$t("main.confIrm"),
                    onClick: () => {
                      this.$router.push("/zbbox");
                    }
                  }
                ]
              });
            } else {
              weui.alert(this.$t("main.payfail"), {
                buttons: [{ label: this.$t("main.confIrm") }]
              });
            }
          }
        );
      });
    },
    format_with_regex(number) {
      return !(number + "").includes(".")
        ? // 就是说1-3位后面一定要匹配3位
          (number + "").replace(/\d{1,3}(?=(\d{3})+$)/g, match => {
            return match + ",";
          })
        : (number + "").replace(/\d{1,3}(?=(\d{3})+(\.))/g, match => {
            return match + ",";
          });
    },
    changeCustom(bool) {
      this.is_custom = bool;
    },
    checkedFlag1(val) {
      this.isKnow = !this.isKnow;
    },
    showContent() {
      Dialog.alert({
        message: this.xy.content,
        confirmButtonColor: "#2d71d7",
        confirmButtonText: this.$t("main.confIrm")
      });
    }
  }
};
</script>

<style scoped>
.out {
  box-sizing: border-box;
  /* background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;

  background: #fff;
  padding: 0.06rem 0.2rem;
  align-items: center;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.Title {
  font-size: 0.16rem;
  position: relative;
}

.Chinese {
  color: #1b6a21;

  font-size: 0.18rem;
}

.right-icon {
  /* width: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
  width: 0.65rem;
}

.cc-wc {
  font-size: 0.1rem;
  color: #e9630a;
}

.body {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.list_item {
  color: #343434;
  font-size: 0.15rem;
  margin-bottom: 0.12rem;
}

.flex {
  display: flex;
  line-height: 0.2rem;
}

.list_input {
  width: 1.9rem;
  height: 0.33rem;

  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 0 0 0.01rem #a0a0a0;

  font-size: 0.14rem;
}

.list_input input {
  border: 0;
  width: 1.9rem;
  height: 0.33rem;
  box-sizing: border-box;
  padding: 0 0.1rem;
  color: #9e9e9e;
  font-size: 0.14rem;
  border-radius: 6px;
  line-height: 0.33rem;
}

.list_input div {
  border: 0;
  width: 1.9rem;
  height: 0.35rem;
  box-sizing: border-box;
  padding: 0 0.1rem;
  color: #9e9e9e;
  font-size: 0.14rem;
  line-height: 0.35rem;
}

.check_box {
  position: relative;
  align-items: center;
  margin-bottom: 0.08rem;
}

.check_box > input {
  visibility: hidden;

  width: 0.19rem;
  height: 0.19rem;
  border-radius: 50%;
  border: none;
  /* box-shadow: 0 0 0 1px #c1c1c1; */
  margin-right: 0.125rem;
}

.check_box label::before {
  display: inline-block;
  content: "";
  width: 0.2rem;
  height: 0.2rem;
  left: 0rem;
  top: 0.095rem;
  position: absolute;
  background: #ffebcc;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #737272;
}

.check_box input:checked + label::before {
  display: inline-block;
  content: "";
  width: 0.2rem;
  height: 0.2rem;
  background: #ffebcc;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #737272, inset 0 0 0 0.025rem #ffebcc,
    inset 0 0 0 0.1rem #601925;
  left: 0rem;
  top: 0.075rem;
  position: absolute;
}

.check_box input:checked + .list_input {
  /* box-shadow: 0 0 0 0.01rem #3662EC; */
}

.agree-box1 input {
  display: none;
}

.agree-box1 {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.2rem;
}

.agree-box1 label::before {
  display: inline-block;
  content: "";
  width: 0.175rem;
  height: 0.175rem;
  background-color: #ffffff;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
}

.agree-box1 input:checked + label::before {
  display: inline-block;
  content: "";
  width: 0.175rem;
  height: 0.175rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: url(../assets/img/rz-select-iocn.png) no-repeat center;
  background-size: cover;
}

.agree-box1 label {
  font-size: 0.12rem;
  margin-left: 0.325rem;
}

.upload {
  position: absolute;
  bottom: 0.33rem;
  width: 3.16rem;
  height: 0.5rem;
  background: url(../assets/img/view-more.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.18rem;
  color: #333;
  margin-left: 0.14rem;
}

.pop {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.pop_mask {
  background: url(../assets/img/kz_pop.png) no-repeat;
  background-size: 100% 100%;
}

.blue_xy {
  color: #2d71d7;
  font-size: 0.12rem;
  line-height: 0.175rem;
}

.en_style .list_item.flex {
  flex-wrap: wrap;
}

.en_style .list_item.flex > div:nth-child(1) {
  margin-bottom: 0.12rem;
}

.zb-top {
  flex-shrink: 0;
  width: 100%;
  height: 1.45rem;
  background: url(../assets/img/culzr_bg.png) no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.15rem;
}

.zb-top img {
  width: 0.94rem;
  height: 0.94rem;
  margin-right: 0.2rem;
}

.zb-top .info {
  display: flex;
  flex-direction: column;
  height: 0.94rem;
  justify-content: space-between;
}
.zb-top .info > div{
    font-size: 0.15rem;
    width: 2.4rem;
    height: 0.21rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.zb-edit {
  flex: 1;
  font-size: 0.13rem;
  background: url(../assets/img/zy_b_bg.png) no-repeat;
  border-radius: 0.08rem 0.08rem 0 0;
  background-size: 100% 100%;
  background-position: center bottom;
  background-attachment: fixed;
  padding: 0.15rem;
  box-sizing: border-box;
  padding-bottom: 0.84rem;
  position: relative;
}

.zb_title {
  display: flex;
  align-items: center;
}

.zb_title img {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.15rem;
}

.zb_text {
  font-size: 0.15rem;
  color: #707070;
}

.zb_main {
  margin-top: 0.15rem;
}

.check_box:nth-child(1) .list_input {
  background: transparent;
  box-shadow: none;
  color: #929292;
}

.zb_tips {
  font-size: 0.12rem;
  color: #929292;
}
</style>